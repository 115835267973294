import { Helmet } from "react-helmet-async";

import classes from "./WhyUs.module.css";
import { companyName } from "../../util/config";

const WhyUs = () => {
  return (
    <section className={classes.container}>
      <Helmet>
        <title>WHY US</title>
        <meta name="description" content={`Why choose ${companyName}`} />
        <link rel="canonical" href="/why-us" />
      </Helmet>
      <h2 className={classes.heading}>WHY US</h2>
      <div className={classes.why}>
        <p>
          At {companyName}, we combine expertise, innovation, and a
          client-focused approach to deliver unparalleled investment solutions
          in the cryptocurrency space. Our team of skilled traders brings years
          of experience and advanced market strategies to the table, ensuring
          that every decision is data-driven and aligned with your financial
          goals. We don't just trade; we manage risk, adapt to market trends,
          and prioritize long-term profitability for our clients. Choosing us
          means putting your trust in a company that is as invested in your
          success as you are.
        </p>
        <p>
          We understand that no two investors are the same, and that's why we've
          designed flexible investment plans that cater to a variety of
          financial needs and risk appetites. From steady growth plans for
          cautious investors to high-yield options for those seeking aggressive
          returns, we offer a tailored approach to wealth creation. Our
          subscription model ensures transparency, making it easy for you to
          track your investments and understand exactly how your portfolio is
          performing.
        </p>
        <p>
          Security is at the heart of everything we do. In the fast-paced and
          often unpredictable world of cryptocurrency, safeguarding your assets
          is our top priority. We utilize state-of-the-art encryption, secure
          wallets, and robust transaction protocols to ensure that your
          investments are protected from potential threats. With {companyName},
          you can have peace of mind knowing that your funds are secure while
          your portfolio grows under professional management.
        </p>
        <p>
          Finally, at {companyName}, we pride ourselves on our dedication to
          client satisfaction. Our support team is always ready to assist you
          with any questions or concerns, providing timely and clear
          communication at every step of your investment journey. We're not just
          a company; we're your partners in financial growth. When you choose{" "}
          {companyName}, you're choosing a trusted ally that's committed to
          helping you achieve your financial aspirations in the exciting world
          of cryptocurrency.
        </p>
      </div>
    </section>
  );
};

export default WhyUs;
