import { FaBuildingLock } from "react-icons/fa6";
import { FaLock } from "react-icons/fa";
import { IoWarningOutline } from "react-icons/io5";
import { SiFsecure } from "react-icons/si";
import { Helmet } from "react-helmet-async";

import classes from "./Security.module.css";
import { companyName } from "../../util/config";

const Security = () => {
  return (
    <section className={classes.container}>
      <Helmet>
        <title>SECURITY</title>
        <meta name="description" content={`Secure at ${companyName}`} />
        <link rel="canonical" href="/security" />
      </Helmet>
      <div className={classes.heading}>
        <h2>Our security gives peace of mind</h2>
        <p>
          Your peace of mind is our highest priority. {companyName} works to
          protect your information, prevent unauthorized account access, and
          notify you of unusual activity.
        </p>
      </div>
      <div className={classes.security}>
        <div className={classes.content}>
          <FaBuildingLock className={classes.lock} />
          <h3>Bank-level Security</h3>
          <p>Secure servers and privacy verified by physical security.</p>
        </div>
        <div className={classes.content}>
          <FaLock className={classes.ssl} />
          <h3>SSL Encryptions</h3>
          <p>Our website and app are secured with 256-bit encryption.</p>
        </div>
        <div className={classes.content}>
          <IoWarningOutline className={classes.warning} />
          <h3>Account Alerts</h3>
          <p>
            We will contact you about unusual account activity for protection
            against fraud.
          </p>
        </div>
        <div className={classes.content}>
          <SiFsecure className={classes.secure} />
          <h3>Account Safeguards</h3>
          <p>
            Multi-factor authentication, automatic logouts, and ID verification
            help prevent unauthorized access.
          </p>
        </div>
      </div>
    </section>
  );
};

export default Security;
