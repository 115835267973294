import { Helmet } from "react-helmet-async";

import classes from "./Cookies.module.css";
import { companyName, email } from "../../util/config";

const Cookies = () => {
  return (
    <section className={classes.container}>
      <Helmet>
        <title>COOKIES POLICY</title>
        <meta name="description" content={`Cookies policy`} />
        <link rel="canonical" href="/cookies-policy" />
      </Helmet>
      <h2 className={classes.heading}>
        {companyName} is fully committed to maintaining the highest standards in
        privacy and compliance.
      </h2>
      <div className={classes.policy}>
        <div className={classes.subHeading}>
          <h2>Privacy and Cookies Notice</h2>
          <p>
            This privacy and cookies notice applies to the processing of
            personal data including information we collect and store via
            cookies, as explained below and references in this policy to “
            {companyName}”, “we” “us” or “our” should be interpreted
            accordingly.
          </p>
        </div>
        <div className={classes.content}>
          <h2>The types of information we process and where it comes from</h2>
          <p>
            Our website uses cookies to distinguish you from other users of our
            website. This helps us to provide you with a good experience when
            you browse our website and also allows us to improve our site.
          </p>
          <p>
            Information you've provided to us, including through our website.
          </p>
          <p>
            Information about our content, products and services you've ordered
            or enquired about, including call recordings as further described
            below.
          </p>
          <p>
            Information provided to us by other organisations, such as address
            verification services, data brokers and credit reference agencies.
            This includes personal data that is publicly available.
          </p>
          <p>
            Information, uploads, user generated content, and comments you make
            about or generate in accessing the products and services you use on
            our platform.
          </p>
          <p>
            Information we collect, via cookies and related technology, from the
            devices you use to access and receive content, products and
            services, about your use of Evergreen Oak Capital, and/or third
            party content, products and services. This includes information
            about your device, machine or browser which can be collected when
            cookies are turned off. For more information on cookies and how to
            manage them, please see the section on cookies.
          </p>
          <p>
            IP address, MAC address, unique identification number, online
            identifier, browser information, location data port information,
            logical network address, and other similar identifying information
            required for your devices to communicate with websites and
            applications on the internet.
          </p>
        </div>
        <div className={classes.content}>
          <h2>Cookies notice</h2>
          <p>
            When you create or log in to an online account you agree to a cookie
            banner which provides a link to the Privacy and Cookies notice.
            Otherwise, by continuing to use our website, content, products or
            services, you agree to the use of cookies as described in this
            notice.
          </p>
          <p>
            You should be aware that when you access or use our content,
            products and services, we may collect information from the devices
            you use to access/ receive Evergreen Oak Capital content, products
            and services by using 'cookies'. We also collect such information
            from third party websites, mobile sites and from apps where we show
            our own adverts, using cookies and related ad technology.
          </p>
          <p>
            If you'd like to learn how to manage these cookies and choose
            whether or not to receive information of different types, please see
            the section "Controlling My Cookies" below.
          </p>
        </div>
        <div className={classes.content}>
          <h2>Controlling My Cookies</h2>
          <p>
            We respect your right to privacy and are therefore happy to provide
            you with tools to manage the cookies you receive from our services.
          </p>
          <p>
            Some cookies are essential to the performance of our platform (you
            cannot opt out from these cookies if you wish to use our platform).
          </p>
          <p>
            Your browser may also allow you to block the storage of cookies on
            your computer; please refer to your browser's “Help” menu or website
            for more information. If you use our services without blocking
            cookies you are actually consenting to the cookies.
          </p>
        </div>
        <div className={classes.content}>
          <h2>How long we keep your information</h2>
          <p>
            We retain your information in accordance with our data retention,
            deletion and security policies. These set out the criteria we use to
            determine how long we keep your information, what measures we put in
            place to keep your information safe and secure. When deciding what
            to retain, we consider what information we need to best provide you
            with products, goods and services, manage your relationship with us,
            meet our statutory obligations and meet our customers and previous
            customers reasonable expectations.
          </p>
        </div>
        <div className={classes.content}>
          <h2>Complaints</h2>
          <p>
            If you wish to make a complaint about how we use your information,
            please contact our support team and we will do our best to help.
          </p>
        </div>
        <div className={classes.content}>
          <h2>Changes to this notice</h2>
          <p>
            We will occasionally update our privacy and cookies notice. We will
            post a notice of any material changes on our website prior to
            implementing the changes, and, where appropriate, notify you using
            any of the contact details we hold for you for this purpose. This
            may include phone, SMS, e-mail, post or interactive social media. We
            encourage you to periodically review our notice to be informed of
            how we use your information.
          </p>
          <p>
            This privacy and cookies notice was last updated 21st December 2019.
          </p>
        </div>
        <div className={classes.content}>
          <h2>Contact</h2>
          <p>
            Questions, comments and requests regarding this cookie policy are
            welcomed and should be addressed to {email}.
          </p>
        </div>
      </div>
    </section>
  );
};

export default Cookies;
