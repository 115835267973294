import classes from "./Usage.module.css";

const Usage = () => {
  return (
    <div className={classes.usage}>
      <div className={classes.content}>
        <span className={classes.value}>$25M+</span>
        <span className={classes.title}>Assets Under Management</span>
      </div>
      <div className={classes.content}>
        <span className={classes.value}>2M+</span>
        <span className={classes.title}>Invested Users</span>
      </div>
      <div className={classes.content}>
        <span className={classes.value}>96%</span>
        <span className={classes.title}>Customer Satisfaction Score</span>
      </div>
    </div>
  );
};

export default Usage;
