import React, { useEffect } from "react";

const TradingViewTicker = () => {
  useEffect(() => {
    // Check if the script is already added
    if (!document.getElementById("tradingview-ticker-script")) {
      const script = document.createElement("script");
      script.id = "tradingview-ticker-script"; // Avoid duplication
      script.src =
        "https://s3.tradingview.com/external-embedding/embed-widget-ticker-tape.js";
      script.async = true;
      script.innerHTML = `
      {
        "symbols": [
          { "proName": "BITSTAMP:BTCUSD", "title": "Bitcoin" },
          { "proName": "BITSTAMP:ETHUSD", "title": "Ethereum" },
          { "proName": "BINANCE:BNBUSDT", "title": "Binance Coin" },
          { "proName": "COINBASE:SOLUSD", "title": "Solana" },
          { "proName": "BINANCE:XRPUSDT", "title": "Ripple" },
          { "proName": "BINANCE:ADAUSDT", "title": "Cardano" }
        ],
        "colorTheme": "dark",
        "isTransparent": true,
        "displayMode": "adaptive",
        "locale": "en"
      }`;
      document
        .getElementById("tradingview-ticker-container")
        .appendChild(script);
    }
  }, []);

  return (
    <div
      id="tradingview-ticker-container"
      style={{
        width: "100%",
        overflow: "hidden",
        position: "relative", // Makes it easier to position at top or bottom
      }}
    ></div>
  );
};

export default TradingViewTicker;
