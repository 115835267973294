import { Navigate } from "react-router-dom";
import { useCookies } from "react-cookie";
import { useDispatch } from "react-redux";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

import { getMe } from "../../api/api";
import { useEffect, useState } from "react";
import { authActions } from "../../store/authSlice";

const ProtectedRoute = ({ children }) => {
  const [auth, setAuth] = useState("pending");
  const { jwt } = useCookies(["jwt"])[0];
  const dispatch = useDispatch();
  useEffect(() => {
    const fetch = async () => {
      const res = await getMe(jwt);
      if (res.status === "success") {
        dispatch(authActions.refresh({ user: res.data.user }));
        setAuth("authenticated");
      } else {
        setAuth("unauthenticated");
        dispatch(authActions.logout());
      }
    };
    fetch();
  }, [jwt, dispatch]);

  return auth === "authenticated" ? (
    children
  ) : auth === "unauthenticated" ? (
    <Navigate to="/login" />
  ) : (
    <div className="loading">
      <Skeleton
        style={{
          display: "block",
          margin: "auto",
          width: "100%",
        }}
        variant="rectangular"
        width="80rem"
        height="80rem"
        baseColor="#087f5b"
        highlightColor="#94d82d"
      />
    </div>
  );
};

export default ProtectedRoute;
