import { useEffect, useRef } from "react";

import classes from "./Hero.module.css";
import { companyName } from "../../util/config";

const Hero = () => {
  const textRefs = useRef([]);
  useEffect(() => {
    const elements = textRefs.current;

    // Check initial visibility and apply the animation
    elements.forEach((element) => {
      if (element) {
        const rect = element.getBoundingClientRect();
        if (rect.top >= 0 && rect.bottom <= window.innerHeight) {
          // Element is visible on initial load
          element.classList.add(classes.visible);
        }
      }
    });

    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add(classes.visible);
          } else {
            entry.target.classList.remove(classes.visible);
          }
        });
      },
      { threshold: 0.1, rootMargin: "0px 0px -10% 0px" }
    );

    elements.forEach((element) => {
      if (element) observer.observe(element);
    });

    return () => {
      elements.forEach((element) => {
        if (element) observer.unobserve(element);
      });
    };
  }, []);
  return (
    <div className={classes.hero}>
      <h1 className={classes.heroText} ref={(el) => (textRefs.current[0] = el)}>
        At {companyName}, our seasoned professionals trade on your behalf,
        ensuring consistent returns. With plans for every investor, from
        beginners to pros, we turn your ambitions into achievements.
      </h1>
      <h1
        className={classes.heroText2}
        ref={(el) => (textRefs.current[1] = el)}
      >
        Our subscription plans are designed to suit your investment goals,
        offering tiered returns based on your chosen level. Whether you're
        building wealth or chasing high yields, we've got you covered.
      </h1>
      <h1
        className={classes.heroText3}
        ref={(el) => (textRefs.current[2] = el)}
      >
        We handle the complexities of crypto trading so you don't have to. With
        secure accounts, real-time performance tracking, and reliable payouts,
        you can invest confidently and watch your wealth grow.
      </h1>
      <h1
        className={classes.heroText4}
        ref={(el) => (textRefs.current[3] = el)}
      >
        Join thousands of investors who trust {companyName} to grow their crypto
        portfolios. We combine advanced trading strategies with transparency,
        delivering real results for every subscriber.
      </h1>
    </div>
  );
};

export default Hero;
