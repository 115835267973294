import Tessa from "../images/harvey.jpeg";
import Ben from "../images/ben.jpeg";
import John from "../images/john.jpeg";
import Ziva from "../images/Ziva.jpeg";
import Donna from "../images/donna.jpeg";
import Nathalie from "../images/natalie.jpeg";
import Dario from "../images/dario.jpeg";
import Nonie from "../images/nonie.jpeg";
import Deven from "../images/Deven.jpeg";
import Derek from "../images/derek.jpeg";
import Gareth from "../images/gareth.jpeg";

export const companyName = "Orohandel";

export const email = "service@orohandel.com";

export const website = "www.orohandel.com";

export const whatsappNumber = "12394758174";

export const address = "11 Wall St, New York, NY 10005, United States";

export const members = [
  { name: "Tessa Harvey", role: "Founder", photo: Tessa },
  { name: "Ben Hughes", role: "Chief Administrative Officer", photo: Ben },
  {
    name: "John S. Schnabel",
    role: "Managing Director",
    photo: John,
  },
  {
    name: "Ziva Cornwell",
    role: "Chief Financial Officer",
    photo: Ziva,
  },
  {
    name: "Donna R. Hurley",
    role: "Business analyst",
    photo: Donna,
  },
  {
    name: "Nathalie Harris",
    role: "Chief Data Scientist",
    photo: Nathalie,
  },
  {
    name: "Dario Salvatore",
    role: "Chief Operating Officer",
    photo: Dario,
  },
  {
    name: "Nonie Myerson",
    role: "Executive Vice President",
    photo: Nonie,
  },
  {
    name: "Deven Gallagher",
    role: "Private Clients Services",
    photo: Deven,
  },
  {
    name: "Derek Gorman",
    role: "Chief Information Officer",
    photo: Derek,
  },
  {
    name: "Gareth Ruse",
    role: "Chief Marketing consultant",
    photo: Gareth,
  },
];
