import classes from "./Tasks.module.css";
import tradingPic from "../../images/task1.jpg";
import plansPic from "../../images/task2.jpg";
import managementPic from "../../images/task3.jpg";
import securityPic from "../../images/task4.jpg";
import { companyName } from "../../util/config";

const Tasks = () => {
  return (
    <section className={classes.container}>
      <h2>Our Tasks</h2>
      <div className={classes.tasks}>
        <div className={classes.task}>
          <div className={classes.photo}>
            <img src={tradingPic} alt="trading-photo" />
          </div>
          <div className={classes.content}>
            <h3>Professional Crypto Trading</h3>
            <p>
              At {companyName}, our team of skilled traders leverages advanced
              algorithms and market analysis to make informed investment
              decisions. We focus on maximizing returns while managing risks,
              allowing our clients to benefit from the dynamic world of
              cryptocurrency without worrying about daily market fluctuations.
              We monitor the markets around the clock, ensuring that your
              investments are always working to their full potential. Whether
              you're a first-time investor or an experienced trader, our goal is
              to help you achieve sustainable growth by strategically managing
              your crypto assets.
            </p>
          </div>
        </div>
        <div className={classes.task}>
          <div className={classes.content}>
            <h3>Tailored Investment Plans</h3>
            <p>
              We believe in personalized investment solutions. With{" "}
              {companyName}, clients can choose from various subscription plans
              designed to meet different investment goals and risk appetites.
              Each plan offers distinct levels of returns, allowing investors to
              pick a strategy that aligns with their financial ambitions.
              Whether you're seeking conservative growth or high-risk,
              high-reward opportunities, we have the right plan for you. Our
              team constantly adjusts strategies based on market trends,
              ensuring your investments continue to perform well within the
              boundaries of your chosen plan.
            </p>
          </div>
          <div className={classes.photo}>
            <img src={plansPic} alt="Plans-photo" />
          </div>
        </div>
        <div className={classes.task}>
          <div className={classes.photo}>
            <img src={managementPic} alt="Management-photo" />
          </div>
          <div className={classes.content}>
            <h3>Hassle-Free Investment Management</h3>
            <p>
              One of the core tasks at {companyName} is to take the complexity
              out of crypto investing. We handle everything for you—from
              portfolio management and trading to withdrawals and reinvestments.
              As a subscriber, you can simply sit back and watch your
              investments grow, while our team ensures your assets are always
              being managed efficiently. We prioritize transparency and
              communication, keeping you updated on your investment performance
              with easy-to-understand reports. No need for deep technical
              knowledge—just a smooth and effortless investment journey.
            </p>
          </div>
        </div>
        <div className={classes.task}>
          <div className={classes.content}>
            <h3>Security and Trust at Our Core</h3>
            <p>
              Security is at the heart of everything we do. At {companyName}, we
              implement state-of-the-art security measures to ensure that your
              investments and personal data are always protected. Our encrypted
              transaction systems and secure wallets ensure that all your crypto
              assets are safe from potential threats. We understand the
              importance of trust, and we work tirelessly to provide a
              transparent, secure, and reliable service. With WERTCHAIN, you can
              rest assured that your crypto investments are in good hands.
            </p>
          </div>
          <div className={classes.photo}>
            <img src={securityPic} alt="security-photo" />
          </div>
        </div>
      </div>
    </section>
  );
};

export default Tasks;
