import { Fragment } from "react";
import {
  Route,
  createBrowserRouter,
  createRoutesFromElements,
  RouterProvider,
  Navigate,
} from "react-router-dom";

import Layout from "./components/layout/Layout";
import HomeLayout from "./components/layout/HomeLayout";
import About from "./components/body/About";
import WhyUs from "./components/body/WhyUs";
import Plans from "./components/plans/Plans";
import ContactUs from "./components/contact/ContactUs";
import ErrorModal from "./components/UI/ErrorModal";
import Terms from "./components/body/Terms";
import Cookies from "./components/body/Cookies";
import Security from "./components/body/Security";
import Privacy from "./components/body/Privacy";
import AvoidScam from "./components/body/AvoidScam";
import Create from "./components/auth/Create";
import Login from "./components/auth/Login";
import VerifyLogin from "./components/auth/VerifyLogin";
import DashboardLayout from "./components/layout/DashboardLayout";
import Investments from "./components/dashboard/Investments";
import TradingViewWidget from "./components/trading-views/TradingViewWidget";
import Password from "./components/dashboard/Password";
import Invest from "./components/invest/Invest";
import ForgotPassword from "./components/auth/ForgotPassword";
import ResetPassword from "./components/auth/ResetPassword";
import ProtectedRoute from "./components/protection/ProtectedRoute";

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path="/" element={<Layout />} errorElement={<ErrorModal />}>
      <Route index element={<HomeLayout />} />
      <Route path="about-us" element={<About />} />
      <Route path="why-us" element={<WhyUs />} />
      <Route path="plans" element={<Plans />} />
      <Route path="contact-us" element={<ContactUs />} />
      <Route path="terms" element={<Terms />} />
      <Route path="cookies-policy" element={<Cookies />} />
      <Route path="security" element={<Security />} />
      <Route path="privacy-policy" element={<Privacy />} />
      <Route path="avoid-scam" element={<AvoidScam />} />
      <Route path="create-account" element={<Create />} />
      <Route path="login" element={<Login />} />
      <Route path="verify-login" element={<VerifyLogin />} />
      <Route
        path="invest/:id"
        element={
          <ProtectedRoute>
            <Invest />
          </ProtectedRoute>
        }
      />
      <Route path="forgotPassword" element={<ForgotPassword />} />
      <Route path="resetPassword" element={<ResetPassword />} />
      <Route
        path="dashboard"
        element={
          <ProtectedRoute>
            <DashboardLayout />
          </ProtectedRoute>
        }
      >
        <Route index element={<TradingViewWidget />} />
        <Route path="investments" element={<Investments />} />
        <Route path="withdraws" element={<Investments />} />
        <Route path="trade-view" element={<TradingViewWidget />} />
        <Route path="password" element={<Password />} />
      </Route>
      <Route path="*" element={<Navigate to="/" />} />
    </Route>
  )
);

function App() {
  return (
    <Fragment>
      <RouterProvider router={router} />
    </Fragment>
  );
}

export default App;
