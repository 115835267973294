import { Helmet } from "react-helmet-async";

import classes from "./AvoidScam.module.css";
import { companyName } from "../../util/config";

const AvoidScam = () => {
  return (
    <section className={classes.container}>
      <Helmet>
        <title>AVOID SCAM</title>
        <meta name="description" content={`Tips to avoid scam`} />
        <link rel="canonical" href="/avoid-scam" />
      </Helmet>
      <div className={classes.heading}>
        <h2>Avoid scams. Follow these tips by {companyName}.</h2>
        <p>
          Despite our best efforts, there will be people who think that{" "}
          {companyName} is a scam site. For them, we've put together a complete
          guide to help you avoid real crypto scams. Here are a few things you
          might want to consider before you invest:
        </p>
      </div>
      <div className={classes.tips}>
        <div className={classes.content}>
          <h2>{companyName} is regulated:</h2>
          <p>
            Not all platforms are regulated. Regulations are put in place to
            protect investors, so you should only trade with regulated
            platforms. {companyName} operates in accordance with the ECB, FCA.
          </p>
        </div>
        <div className={classes.content}>
          <h2>{companyName} is safe and secure:</h2>
          <p>
            Always look for security signs on your browser before you trade. On{" "}
            {companyName}, clients funds are kept secured in tier 1 banks, and
            all of their personal information is guarded under SSL encryption.
          </p>
        </div>
        <div className={classes.content}>
          <h2>{companyName} has round-the-clock support:</h2>
          <p>
            The support team is available 24 hours a day on trading days,
            meaning {companyName} clients always have someone to turn to if they
            encounter a problem or require assistance.
          </p>
        </div>
        <div className={classes.content}>
          <h2>{companyName} has real users:</h2>
          <p>
            {companyName} encourages its users to share their real name and
            picture to ensure transparency. Moreover, depositing clients are
            subject to a strict verification process, making sure they are who
            they claim to be
          </p>
        </div>
        <div className={classes.content}>
          <h2>Technology and design:</h2>
          <p>
            If the platform seems too complex to use, it probably isn't right
            for you. {companyName} simple interface offers some of the world's
            leading fintech instruments, online learning courses, an innovative
            web app, and much more. It is safe to assume that scammers don't go
            to such lengths to benefit their users.
          </p>
        </div>
        <div className={classes.content}>
          <h2>{companyName} makes its clients aware of the risks: </h2>
          <p>
            Any form of investment, trading, be it traditional stockbroker
            trading, or Contract for Difference (CFD) trading, involves risk.{" "}
            {companyName} encourages its clients to take safety measures and
            provides various tools for reducing risk while encouraging
            responsible trading.
          </p>
        </div>
        <div className={classes.content}>
          <h2>Trading is not gambling:</h2>
          <p>
            While there are always unforeseen events that can cause a sudden
            shift in an asset's price, resulting in a loss, responsible traders
            stay informed and diversify their portfolios to reduce risk.
          </p>
        </div>
        <div className={classes.content}>
          <h2>Why some people say it's a scam:</h2>
          <p>
            Regulated trading platforms cannot be scams since they act under
            strict supervision. Trading involves risk, and some people who lose
            money trading online are quick to blame the platform, calling it a
            “scam.” That is one of the reasons why {companyName}
            provides risk management tools and allows you to withdraw funds at
            any time.
          </p>
        </div>
      </div>
    </section>
  );
};

export default AvoidScam;
