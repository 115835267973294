import { Fragment } from "react";
import { Helmet } from "react-helmet-async";

import Hero from "../body/Hero";
import Tasks from "../body/Tasks";
import Usage from "../body/Usage";
import Partners from "../body/Partners";
import Plans from "../plans/Plans";
import { companyName } from "../../util/config";

const HomeLayout = () => {
  return (
    <Fragment>
      <Helmet>
        <title>HOME</title>
        <meta name="description" content={`Welcome to ${companyName}`} />
        <link rel="canonical" href="/" />
      </Helmet>
      <Hero />
      <Plans />
      <Tasks />
      <Usage />
      <Partners />
    </Fragment>
  );
};

export default HomeLayout;
