import { Link } from "react-router-dom";
import { Helmet } from "react-helmet-async";

import classes from "./Privacy.module.css";
import { companyName, email, website } from "../../util/config";

const Privacy = () => {
  return (
    <section className={classes.container}>
      <Helmet>
        <title>PRIVACY POLICY</title>
        <meta name="description" content={`Privacy policy`} />
        <link rel="canonical" href="/privacy-policy" />
      </Helmet>
      <h2 className={classes.heading}>
        {companyName} aims to offer its clients the most transparent framework
        and discloses all cooperation terms in detail. Creating a secure
        environment for our clients is our first priority.
      </h2>
      <div className={classes.policy}>
        <div className={classes.subHeading}>
          <h2>Our privacy policy</h2>
          <p>
            {companyName} ("We") is committed to maintaining the
            confidentiality, integrity and security of any personal information
            about our users.
          </p>
        </div>
      </div>
      <div className={classes.content}>
        <h2>Information we collect from you</h2>
        <ul>
          <li>
            Information you give us. This is information about you that you give
            us by filling in forms on our site{" "}
            <Link to={website}>{companyName}</Link> or otherwise in writing with
            us, or by corresponding with us by phone, e-mail. It includes
            information you provide when you register with us and information
            you provide to our third party partners, agents, representatives or
            nominees and when you report a problem with our site. The
            information you give us may include your name, address, e-mail
            address and phone number, financial and credit card information,
            personal description, photography and any other relevant
            information.
          </li>
          <li>
            Information we collect about you. With regard to each of your visits
            to our site we will automatically collect the following information:
            <ul>
              <li>
                Transactional information. We collect information about any
                transfer and transaction you may complete on our site, including
                the amount of funds or tokens associated with a trade, the types
                of trades executed any relevant information relating to such
                trades
              </li>
              <li>
                Technical information, including the Internet protocol (IP)
                address used to connect your computer to the Internet, your
                login information, browser type and version, time zone setting,
                browser plug-in types and versions, operating system and
                platform;
              </li>
              <li>
                Information about your visit, including the full Uniform
                Resource Locators (URL), clickstream to, through and from our
                site (including date and time), products you viewed or searched
                for, page response times, download errors, length of visits to
                certain pages, page interaction information (such as scrolling,
                clicks, and mouse-overs), methods used to browse away from the
                page, and any phone number used to call our customer service
                number.
              </li>
            </ul>
          </li>
          <li>
            Information we receive from other sources. This is information we
            receive about you if you use any of the other websites we operate or
            the other services we provide. In this case we will have informed
            you when we collected that data if we intend to share those data
            internally and combine it with data collected on this site. We will
            also have told you for what purpose we will share and combine your
            data. We are working closely with third parties (including, for
            example, business partners, sub-contractors in technical, payment
            and delivery services, advertising networks, analytics providers,
            search information providers). We will notify you when we receive
            information about you from them and the purposes for which we intend
            to use that information.
          </li>
        </ul>
      </div>
      <div className={classes.content}>
        <h2>Cookies</h2>
        <p>
          Our website uses cookies to distinguish you from other users of our
          website. This helps us to provide you with a good experience when you
          browse our website and also allows us to improve our site.
        </p>
        <p>
          Cookies are typically stored on your computer's hard drive.
          Information collected from cookies is used by us to evaluate the
          effectiveness of our Site, analyse trends, and administer the
          Platform. The information collected from cookies allows us to
          determine such things as which parts of our Site are most visited and
          difficulties our visitors may experience in accessing our Site. With
          this knowledge, we can improve the quality of your experience on the
          Platform by resolving access difficulties and recognizing and
          delivering more of the most desired features and information.
        </p>
        <p>
          We also use cookies and/or a technology known as web bugs or clear
          gifs, which are typically stored in emails to help us confirm your
          receipt of, and response to, our emails and to provide you with a more
          personalized experience when using our Site.
        </p>
        <p>
          We use third party service provider(s), to assist us in better
          understanding the use of our Site. Our service provider(s) will place
          cookies on the hard drive of your computer and will receive
          information we select, that will educate us on such things as how
          visitors navigate around our site, what products are browsed, and
          general Transaction information. Our service provider(s) analyses this
          information and provides us with aggregate reports. The information
          and analysis provided by our service provider(s) will be used to
          assist us in better understanding our visitors' interests in our Site
          and how to better serve those interests.
        </p>
        <p>
          The information collected by our service provider(s) may be linked to
          and combined with information that we collect about you while you are
          using the Platform. Our service provider(s) is/are contractually
          restricted from using information they receive from our Site other
          than to assist us.
        </p>
        <p>
          By using our Site you are agreeing that we may use cookies for the
          purposes set out above
        </p>
        <p>
          You can find out more about Evergreen Oak Capital’s use of Cookies in
          our cookie policy
        </p>
      </div>
      <div className={classes.content}>
        <h2>Uses made of the information</h2>
        <ul>
          <li>
            Information you give us. We will use this information:
            <ul>
              <li>
                to carry out our obligations arising from any contracts entered
                into between you and us and to provide you with the information,
                products and services that you request from us;
              </li>
              <li>
                to provide you with information about other goods and services
                we offer that are similar to those that you have already
                purchased or enquired about;
              </li>
              <li>
                to provide you, or permit selected third parties to provide you,
                with information about goods or services we feel may interest
                you. If you are an existing customer, we will only contact you
                by electronic means (e-mail or SMS) with information about goods
                and services similar to those which were the subject of a
                previous sale or negotiations of a sale to you. If you are a new
                customer, and where we permit selected third parties to use your
                data, we (or they) will contact you by electronic means only if
                you have consented to this. If you do not want us to use your
                data in this way, or to pass your details on to third parties
                for marketing purposes, please tick the relevant box situated on
                the form on which we collect your data;
              </li>
              <li>to notify you about changes to our service; or</li>
              <li>
                to ensure that content from our site is presented in the most
                effective manner for you and for your computer.
              </li>
            </ul>
          </li>
          <li>
            Information we collect about you. We will use this information:
            <ul>
              <li>
                to administer our site and for internal operations, including
                troubleshooting, data analysis, testing, research, statistical
                and survey purposes;
              </li>
              <li>
                to improve our site to ensure that content is presented in the
                most effective manner for you and for your computer;
              </li>
              <li>
                to allow you to participate in interactive features of our
                service, when you choose to do so;
              </li>
              <li>
                as part of our efforts to keep our site safe and secure; or
              </li>
              <li>
                to measure or understand the effectiveness of advertising we
                serve to you and others, and to deliver relevant advertising to
                you; or
              </li>
              <li>
                to make suggestions and recommendations to you and other users
                of our site about goods or services that may interest you or
                them.
              </li>
            </ul>
          </li>
          <li>
            Information we receive from other sources. We will combine this
            information with information you give to us and information we
            collect about you. We will use this information and the combined
            information for the purposes set out above (depending on the types
            of information we receive).
          </li>
        </ul>
        <p>
          We will use and process your Personal Information only for the
          purpose(s) for which it has been provided to us.
        </p>
      </div>
      <div className={classes.content}>
        <h2>Where we store your personal data</h2>
        <p>
          All personal information you provide to us is stored on our secure
          servers. Any payment transactions will be encrypted. Where we have
          given you (or where you have chosen) a password which enables you to
          access certain parts of our site, you are responsible for keeping this
          password confidential. We ask you not to share a password with anyone
        </p>
        <p>
          Unfortunately, the transmission of information via the internet is not
          completely secure. Although we will do our best to protect your
          personal data, we cannot guarantee the security of your data
          transmitted to our site; any transmission is at your own risk. Once we
          have received your information, we will use strict procedures and
          security features to try to prevent unauthorized access.
        </p>
      </div>
      <div className={classes.content}>
        <h2>Your rights</h2>
        <p>
          You have the right to ask us not to process your personal data for
          marketing purposes. We will usually inform you (before collecting your
          data) if we intend to use your data for such purposes or if we intend
          to disclose your information to any third party for such purposes. You
          can exercise your right to prevent such processing by checking certain
          boxes on the forms we use to collect your data. You can also exercise
          the right at any time by contacting us at{" "}
          <Link to={email}>{email}</Link>
        </p>
      </div>
      <div className={classes.content}>
        <h2>Changes to our privacy policy</h2>
        <p>
          Any changes we make to our privacy policy in the future will be posted
          on this page and, where appropriate, notified to you by e-mail. Please
          check back frequently to see any updates or changes to our privacy
          policy.
        </p>
      </div>
      <div className={classes.content}>
        <h2>Contact</h2>
        <p>
          Questions, comments and requests regarding this privacy policy are
          welcomed and should be addressed to <Link to={email}>{email}</Link>
        </p>
      </div>
    </section>
  );
};

export default Privacy;
