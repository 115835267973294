import React, { useEffect } from "react";
import { Helmet } from "react-helmet-async";

const TradingViewWidget = () => {
  useEffect(() => {
    // Check if the script is already added
    if (!document.getElementById("tradingview-widget-script")) {
      const script = document.createElement("script");
      script.id = "tradingview-widget-script"; // Add an ID to prevent duplication
      script.src =
        "https://s3.tradingview.com/external-embedding/embed-widget-screener.js";
      script.async = true;

      // Add the widget configuration as the script's inner content
      script.innerHTML = `
      {
        "width": "100%",
        "height": "800",
        "defaultColumn": "overview",
        "screener_type": "crypto_mkt",
        "displayCurrency": "USD",
        "colorTheme": "dark",
        "locale": "en",
        "isTransparent": true
      }`;

      // Append the script to the widget container
      const container = document.getElementById("tradingview-widget-container");
      container.appendChild(script);
    }
  }, []); // Empty dependency array ensures this runs only once after mounting

  return (
    <div
      className="tradingview-widget-container"
      id="tradingview-widget-container"
      style={{
        width: "100%",
        height: "800", // Steady height
        position: "relative",
        overflow: "hidden",
      }}
    >
      <Helmet>
        <title>TRADING VIEW</title>
        <meta name="description" content={`Trading view`} />
        <link rel="canonical" href="/trade-view" />
      </Helmet>
      <div className="tradingview-widget-container__widget"></div>
      <div className="tradingview-widget-copyright">
        <a
          href="https://www.tradingview.com/"
          //   rel="noopener nofollow"
          target="_blank"
          rel="noreferrer"
        >
          <span className="blue-text">Track all markets on TradingView</span>
        </a>
      </div>
    </div>
  );
};

export default TradingViewWidget;
