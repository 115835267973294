import { NavLink } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import classes from "./Nav.module.css";
import { authActions } from "../../store/authSlice";

const Nav = () => {
  const isLoggedIn = useSelector((state) => state.auth.isLoggedIn);
  const dispatch = useDispatch();

  const logoutHandler = () => {
    dispatch(authActions.logout());
  };
  return (
    <nav className={classes.nav}>
      <NavLink
        to="/"
        className={(navData) => (navData.isActive ? classes.active : "")}
      >
        HOME
      </NavLink>
      <NavLink
        to="/about-us"
        className={(navData) => (navData.isActive ? classes.active : "")}
      >
        ABOUT US
      </NavLink>
      <NavLink
        to="/contact-us"
        className={(navData) => (navData.isActive ? classes.active : "")}
      >
        CONTACT US
      </NavLink>
      <NavLink
        to="/why-us"
        className={(navData) => (navData.isActive ? classes.active : "")}
      >
        WHY US
      </NavLink>
      <NavLink
        to="/plans"
        className={(navData) => (navData.isActive ? classes.active : "")}
      >
        PLANS
      </NavLink>
      {isLoggedIn && (
        <NavLink
          to="/login"
          className={(navData) => (navData.isActive ? classes.active : "")}
          onClick={logoutHandler}
        >
          LOGOUT
        </NavLink>
      )}
      {!isLoggedIn && (
        <NavLink
          to="/login"
          className={(navData) => (navData.isActive ? classes.active : "")}
        >
          LOGIN
        </NavLink>
      )}
      {!isLoggedIn && (
        <NavLink
          to="/create-account"
          className={(navData) => (navData.isActive ? classes.active : "")}
        >
          GET STARTED
        </NavLink>
      )}
      {isLoggedIn && (
        <NavLink
          to="/dashboard"
          className={(navData) => (navData.isActive ? classes.active : "")}
        >
          DASHBOARD
        </NavLink>
      )}
    </nav>
  );
};

export default Nav;
